<template>
  <div class="top-content">
    <el-button
      @click="handleAddStaffClick"
      type="primary"
    >添加员工</el-button>
    <el-button type="primary">批量移除</el-button>
    <add-staff-dialog
      :dialogVisible='addStaffDialogVisible'
      @changeDialogVisible='changeAddStaffDialogVisible'
    ></add-staff-dialog>

  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'
import AddStaffDialog from './cpns/add-staff-dialog/add-staff-dialog'
export default defineComponent({
  props: {

  },
  components: {
    AddStaffDialog
  },
  setup() {
    const addStaffDialogVisible=ref(false)
    const changeAddStaffDialogVisible=(flag)=>{
        addStaffDialogVisible.value=flag
    }
    const handleAddStaffClick=()=>{
         addStaffDialogVisible.value=true
    }
    return {
      addStaffDialogVisible,
      changeAddStaffDialogVisible,
      handleAddStaffClick
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  background-color: white;
  padding: 16px;
  border-top: 1px solid var(--borderColor);
}
</style>




