<template>
  <div class="option-time">
    <div class="title">第{{ index + 1 }}天</div>
    <div class="times">
      <el-select v-model="ruleForm.id" class="m-2" placeholder="请选择班次" size="large">
        <el-option
          v-for="item in shiftsettingstaffList"
          :key="item.id"
          :label="item.shiftName + item.startTime + '-' + item.endTime"
          :value="item.id"
        />
      </el-select>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '第一天'
    },
    ruleForm: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 1
    },


  },
  components: {

  },
  setup() {
    const store = useStore()

    const shiftsettingstaffList = computed(() => store.state.staff.shiftsettingstaffList)

    const initPage = () => {
      store.dispatch('staff/getshiftsettingstaffListAction', {
        companyId: COMPANY_ID,
        shopId:0
      })
    }
    initPage()


    return {
      shiftsettingstaffList
    }

  }
})
</script>

<style scoped lang="less">
.option-time {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  .title {
    min-width: 80px;
  }
  .times {
    display: flex;
    .startTime {
      margin-right: 20px;
    }
  }
}
</style>




