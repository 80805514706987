<template>
  <div class="top-content">
    <div>
      <el-select
        clearable
        @change="handleShopChange"
        v-model="ruleForm.shopId"
        class="m-2"
        placeholder="选择门店"
      >
        <el-option
          v-for="item in shopList"
          :key="item.shopId"
          :label="item.shopName"
          :value="item.shopId"
        >
        </el-option>
      </el-select>

      <el-date-picker
        @change="change"
        v-model="ruleForm.month"
        type="month"
        placeholder="选择月份"
      ></el-date-picker>
      <div class="time_show">
        <p v-for="(item , index) in shiftsettingstaffList" :key="index">{{item.shiftName}}：{{item.startTime}}-{{item.endTime}}</p>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import AddRoleDialog from './cpns/add-rank-dialog/add-rank-dialog'
import { getCalendar } from '@/utils/time'
import emitter from '@/utils/eventbus'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import cache from '@/utils/cache'
export default defineComponent({
  props: {

  },
  components: {
    AddRoleDialog,
  },
  emits: ['panel-change'],
  setup() {
    const store = useStore()
    const ruleForm = reactive({
      month: dayjs(new Date()).format('YYYY-MM'),
      shopId: ''
    })
    const router = useRouter()
    const addRoleDialogVisible = ref(false)
    const handleAddRoleClick = () => {
      addRoleDialogVisible.value = true
    }
    const changeAddRoleDialogVisible = (flag) => {
      addRoleDialogVisible.value = flag
      emitter.emit('addStaffScheduleDateChange', dayjs(ruleForm.month).format('YYYY-MM'))
    }

    const datePanelChange = (date, mode, view) => {

    }

    const handleShopChange = value => {
      emitter.emit('addStaffScheduleShopIdChange', value)
    }

    const change = (date) => {
      const dateArr = getCalendar(date)
      emitter.emit('staffSchedulingMonthChange', dateArr)
      emitter.emit('addStaffScheduleDateChange', dayjs(date).format('YYYY-MM'))
    }

    const handleShiftSettingClick = () => {
      router.push('/main/staff/shiftSettings')
    }

    const shopList = computed(() => store.state.reserve.shopList)
    const shiftsettingstaffList = computed(() => store.state.staff.shiftsettingstaffList)
    const initPage = () => {
      store.dispatch('reserve/getShopListAction3', COMPANY_ID)
      let adminInfo = cache.getCache('adminInfo')
      let newShopId = cache.getCache('newShopId')

    ruleForm.shopId = newShopId?.id||shopList.value['0'].shopId
    store.dispatch('staff/getshiftsettingstaffListAction', {
        companyId: COMPANY_ID,
        shopId: ruleForm.shopId
    })
      
    }
    initPage()

    return {
      shopList,
      shiftsettingstaffList,
      handleAddRoleClick,
      changeAddRoleDialogVisible,
      addRoleDialogVisible,
      ruleForm,
      datePanelChange,
      change,
      handleShiftSettingClick,
      COMPANY_ID,
      handleShopChange
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  padding: 18px 0;
  .other {
    color: rgba(42, 130, 228, 1);
    font-size: 14px;
  }

  &:deep(.el-select) {
    max-width: 180px !important;
    min-width: 120px;
    margin-right: 10px;
  }
  div{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .time_show{
    // width: 200px;
    margin-top: 20px;
    font-size: 14px;
    p{
      margin: 0 15px;
    }
  }
}
</style>
