<template>
  <div class="add-week-schedule-dialog">
    <page-dialog
      @handleSaveClick="handleSaveClick"
      :isShowFooter="true"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      title="修改周期"
    >
      <div class="form">
        <div class="name">
          <div class="title">周期名称：</div>
          <el-input v-model="ruleForm.perName" placeholder="名称" />
        </div>
        <div class="day-num">
          <div class="title">周期天数：</div>
          <el-input
            @input="handleDayNumClick"
            min="1"
            type="number"
            v-model="ruleForm.perDays"
            placeholder="天数"
          >
            <template #suffix>| 天数</template>
          </el-input>
        </div>
        <div class="time-list">
          <el-scrollbar height="250px">
            <template :key="item" v-for="(item, index) in weekDayList">
              <option-time :index="index" :ruleForm="weekDayList[index]"></option-time>
            </template>
          </el-scrollbar>
        </div>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import {
  defineComponent,
  reactive,
  ref, watch
} from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import OptionTime from './option-time/option-time.vue'
import { nextTick } from 'process'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    weekInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    PageDialog,
    OptionTime
  },
  emits: ['changeDialogVisible'],
  setup(props, {
    emit
  }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleDayNumClick = (value) => {
      if (Number(value) < 1) {
        ruleForm.perDays = 1
      } else if (Number(value) > 31) {
        ruleForm.perDays = 31
      }

      weekDayList.value = []
      nextTick(() => {
        for (let i = 0; i < ruleForm.perDays; i++) {
          if (props.weekInfo.shiftList[i]) {
            weekDayList.value[i] = {
              id: props.weekInfo.shiftList[i].shiftId
            }
          } else {
            weekDayList.value.push({
              id: null
            })
          }
        }
      })
    }

    const weekDayList = ref([
    ])

    const ruleForm = reactive({
      perName: '',
      perDays: 1,
      weekId: '',
      status: 1,
    })

    const emptyForm = () => {
      ruleForm.perName = ''
      ruleForm.perDays = 1
      weekDayList.value = [
        {
          id: null
        }
      ]
    }

    const handleSaveClick = (num) => {
      let shiftIds = []
      weekDayList.value.forEach(item => {
        shiftIds.push(item.id)
      })
      let obj = {
        companyId: COMPANY_ID,
        perName: ruleForm.perName,
        perDays: ruleForm.perDays,
        shiftIds: shiftIds.join(),
        id: ruleForm.weekId,
        status: ruleForm.status
      }
      store.dispatch('staff/updateStaffScheduleWeekInfoAction', obj)
      emptyForm()
    }

    watch(() => props.weekInfo, (n) => {
      weekDayList.value = []
      nextTick(() => {
        if (n != null) {
          ruleForm.perName = n.perName
          ruleForm.perDays = n.perDays
          ruleForm.weekId = n.id
          ruleForm.status = n.status
          n.shiftList.forEach(item => {
            weekDayList.value.push({
              id: Number(item.shiftId)
            })
          })
        }
      })
    })


    return {
      changeDialogVisible,
      ruleForm,
      handleSaveClick,
      weekDayList,
      handleDayNumClick,
      emptyForm

    }

  }
})
</script>

<style scoped lang="less">
.add-week-schedule-dialog {
  .form {
    .name,
    .day-num {
      margin-bottom: 20px;
      .title {
        min-width: 80px;
      }
      display: flex;
      align-items: center;
    }
    .time {
    }
  }
}
</style>
