<template>
  <div class="add-schedule-dialog">
    <page-dialog
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      :title="editInfo.name + editInfo.date + '-' + editInfo.day + '的排班'"
    >
      <el-tabs
        @tab-click="handleTabsClick"
        v-model="activeName"
        stretch
        type="card"
        class="tabs"
      >
        <el-tab-pane name="first" label="按班次设置">
          <!-- <div class="tips">
            从选中日期开始,按周期排班
            直至本月最后一天
          </div> -->
          <div class="options">
            <el-scrollbar height="250px">
              <el-radio-group v-model="ruleForm.shift">
                <template :key="item" v-for="item in shiftsettingstaffList">
                  <el-radio
                    @click="handleRadioClick(item)"
                    :label="item.shiftName + ':' + item.startTime + '-' + item.endTime"
                  />
                </template>
              </el-radio-group>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <el-tab-pane name="second" label="按周期设置">
          <!-- <div class="tips">
            从选中日期开始,按周期排班
            直至本月最后一天
          </div> -->
          <div class="options">
            <el-scrollbar height="250px">
              <el-radio-group v-model="ruleForm.week">
                <template :key="item" v-for="item in scheduleWeekList">
                  <el-radio @click="handleWeekRadioClick(item)" :label="item.perName" />
                </template>
              </el-radio-group>
            </el-scrollbar>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span class="dialog-footer">
        <el-button type="primary" v-has="'staff:scheduling'" size="small" @click="handleSaveClick"
          >保存</el-button
        >
      </span>
    </page-dialog>
  </div>
</template>

<script lang="js">
import {
  defineComponent,
  reactive,
  ref,
  computed,
  watch
} from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editInfo: {
      type: Object,
      default() {
        return {}
      }
    }

  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'handleSaveClickScheduleClick'],
  setup(props, {
    emit
  }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleSaveClick = () => {
      // console.log({ ...currentOptSchedule });

      emit('handleSaveClickScheduleClick', { ...currentOptSchedule })
      changeDialogVisible(false)
    }

    const ruleForm = reactive({
      shift: "",
      week: ''
    })

    const activeName = ref('first')
    const handleTabsClick = (name) => {

    }

    const currentOptSchedule = reactive({
      shiftId: '',
      shiftStartTime: '',
      shiftEndTime: '',
      weekInfo: '',
      active: activeName.value,
      shiftName:""
    })

    watch(activeName, (n) => {
      currentOptSchedule.active = n

    })

    const handleRadioClick = (item) => {
      // console.log(item);
      currentOptSchedule.shiftStartTime = item.startTime
      currentOptSchedule.shiftEndTime = item.endTime
      currentOptSchedule.shiftId = item.id
      currentOptSchedule.shiftName = item.shiftName
    }

    const handleWeekRadioClick = (item) => {
      // console.log(item);
      currentOptSchedule.weekInfo = item
    }

    const shiftsettingstaffList = computed(() => store.state.staff.shiftsettingstaffList)
    const scheduleWeekList = computed(() => store.state.staff.scheduleWeekList)

    watch(shiftsettingstaffList, (n) => {

    })
    const intPage = () => {
      store.dispatch('staff/getshiftsettingstaffListAction', {
        companyId: COMPANY_ID,
        shopId:props.editInfo.shopId
      })
      store.dispatch('staff/getScheduleWeekList', COMPANY_ID)
    }
    intPage()

    return {
      shiftsettingstaffList,
      changeDialogVisible,
      ruleForm,
      handleSaveClick,
      handleRadioClick,
      currentOptSchedule,
      scheduleWeekList,
      handleWeekRadioClick,
      activeName,
      handleTabsClick
    }

  }
})
</script>

<style scoped lang="less">
.add-schedule-dialog {
  .tabs {
    &:deep(.options) {
      display: flex;
      flex-direction: column;

      label {
        // display: block!important;
        display: flex;
        align-items: center;
      }

      .el-radio-group {
        width: 100%;
      }

      .el-radio.is-checked {
        padding: 0 6px;
        background-color: rgba(255, 244, 244, 1);
        margin-right: 0 !important;
        box-shadow: rgba(204, 204, 204, 1) solid 1px;
        border-radius: 5px;
        font-size: 14px;
      }
    }

    &:deep(.tips) {
      color: rgba(128, 128, 128, 1);
      font-size: 12px;
      text-align: center;
      margin: 6px 0 10px 0;
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
  }
}
</style>
