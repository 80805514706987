<template>
  <div class="department-management">
    <breadcrumb class="breadcrumb" :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="content">
      <el-tabs v-model="activeName" type="card" class="tabs" @tab-click="handleClick">
        <el-tab-pane label="排班设置" name="first">
          <top-content></top-content>
          <staff-list></staff-list>
        </el-tab-pane>
        <el-tab-pane label="周期设置" name="second">
          <week-setting></week-setting>
        </el-tab-pane>
        <el-tab-pane label="班次设置" name="third">
          <shift-settings></shift-settings>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import TopContent from './cpns/top-content/top-content'
import StaffList from './cpns/staff-list/staff-list'
import ShiftSettings from '../shift-settings/shift-settings.vue'
import WeekSetting from '../shift-settings/cpns/tabs/cpns/week-setting/week-setting.vue'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    TopContent,
    StaffList,
    ShiftSettings,
    WeekSetting
  },
  name:"staffStaffScheduling",
  setup() {
    const activeName = ref('first')

    return {
      breadcrumbList,
      activeName
    }

  }
})
</script>

<style scoped lang="less">
.department-management {
  box-sizing: border-box;
  min-width: 980px;

  .breadcrumb {
    margin-bottom: 16px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    background-color: white;
    padding: 20px;

    .tabs {
      width: 100%;
    }
  }
}
</style>












