export const breadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '员工排班',
    path: ''
  }
]
