<template>
  <div class="staff-list" v-loading.fullscreen.lock="loading" element-loading-text="数据加载中，请稍等……">
    <page-content
      align="center"
      :isBorder="true"
      :dataList="scheduleStaffList"
      @cellDblclick="cellDblclick"
      :contentTableConfig="pageContentTableConfig"
      pageName="scheduleStaff"
      category="staff"
      :isButtonStyle="true"
      @selectionChange="selectionChange"
      @tableCellClick="tableCellClick"
      :queryInfo="queryInfo"
      :isShowTooltip="false"
    >
      <template #userRealname="scope">
        <div class="emp-name">
          <div class="userRealname">{{ scope.row.userRealname }}</div>
        </div>
      </template>
      <template #isReserve="scope">
        <div class="handle-wrap">
          <template v-if="scope.row.showInShop == 1">
            <el-switch active-value="1" inactive-value="0" v-model="active" />
          </template>
          <template v-else>
            <el-switch active-value="1" inactive-value="0" v-model="inactive" />
          </template>
          <div
            @click.stop="handleSetReserveState(scope.row)"
            class="el-switch-mask"
          ></div>
        </div>
      </template>
      <template #1="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[1]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-01'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #2="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[2]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-02'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #3="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[3]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-03'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #4="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[4]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-04'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #5="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[5]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-05'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #6="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[6]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-06'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #7="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[7]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-07'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #8="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[8]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-08'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #9="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[9]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-09'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #10="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[10]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-10'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #11="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[11]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-11'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #12="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[12]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-12'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #13="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[13]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-13'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #14="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[14]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-14'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #15="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[15]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-15'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #16="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[16]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-16'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #17="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[17]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-17'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #18="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[18]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-18'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #19="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[19]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-19'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #20="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[20]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-20'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #21="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[21]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-21'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #22="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[22]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-22'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #23="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[23]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-23'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #24="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[24]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-24'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #25="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[25]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-25'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #26="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[26]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-26'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #27="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[27]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-27'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #28="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[28]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-28'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #29="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[29]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-29'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
      <template #30="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[30]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-30'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
	  <template #31="scope">
        <el-scrollbar>
          <div class="schedule">
            <template :key="item" v-for="item in scope.row[31]">
              <div class="item" :style="{color:newDate>currentEditScheduleInfo.date + '-31'?'#ccc':''}">
                <div class="name">{{ item.shiftName }}</div>
                <!-- <div class="time">{{ item.startTime }}-{{ item.endTime }}</div> -->
              </div>
            </template>
          </div>
        </el-scrollbar>
      </template>
    </page-content>
    <edit-staff-draw
      @changedDrawer="changedEditStaffDrawer"
      :isShowDraw="isShowEditStaffDraw"
    ></edit-staff-draw>
    <add-schedule-dialog
      v-if="addScheduleDialogVisible"
      :editInfo="currentEditScheduleInfo"
      @handleSaveClickScheduleClick="handleSaveClickScheduleClick"
      :dialogVisible="addScheduleDialogVisible"
      @changeDialogVisible="changeAddScheduleDialogVisible"
    ></add-schedule-dialog>
    <edit-schedule-dialog
      @closeEditScheduleDialog="closeEditScheduleDialog"
      :scheduleInfo="currentUpdateScheduleInfo"
      @changeDialogVisible="changeEditScheduleDialogVisible"
      :dialogVisible="editScheduleDialogVisible"
    >
    </edit-schedule-dialog>
    <div class="pagination">
      <el-pagination
        v-model:currentPage="queryInfo.page"
        v-model:page-size="queryInfo.pagesize"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="schedulestaffCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted, onMounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config/index'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import TopContent from './cpns/top-content/top-content'
import Breadcrumb from './cpns/breadcrumb/breadcrumb'
import EditStaffDraw from './cpns/edit-staff-draw/edit-staff-draw'
import emitter from '@/utils/eventbus'
import AddScheduleDialog from './cpns/add-schedule-dialog/add-schedule-dialog.vue'
import { getCurrentInstance } from 'vue'
import { COMPANY_ID } from '@/common/constant'
import dayjs from 'dayjs'
import { useStore } from 'vuex'
import editScheduleDialog from './cpns/edit-schedule-dialog/edit-schedule-dialog.vue'
import cache from '@/utils/cache'
import { showInShop } from '@/service/main/staff'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {},
  components: {
    PageContent,
    PageDraw,
    PageDialog,
    TopContent,
    Breadcrumb,
    EditStaffDraw,
    AddScheduleDialog,
    editScheduleDialog
  },
  setup() {
    const store = useStore()
    const isShowEditStaffDraw = ref(false)
    const pageContentTableConfig = ref(contentTableConfig)
    const addScheduleDialogVisible = ref(false)
    const loading = ref(false)
    const handleDetailClick = (item) => {

      isShowEditStaffDraw.value = true
    }

    const editScheduleDialogVisible = ref(false)
    const changeEditScheduleDialogVisible = (flag) => {
      editScheduleDialogVisible.value = flag
    }
    const changedEditStaffDrawer = (flag) => {
      isShowEditStaffDraw.value = flag
    }
    const datab = getCurrentInstance()

    emitter.on('staffSchedulingMonthChange', (arr) => {
      pageContentTableConfig.value.propList = [
        { prop: 'userRealname', label: '姓名', minWidth: '140', slotName: 'userRealname', fixed: true },
        {
          prop: 'isReserve',
          label: '是否开启预约',
          minWidth: '140',
          slotName: 'isReserve',
          fixed: true
        }
      ]
      arr.forEach(item => {
        pageContentTableConfig.value.propList.push({
          prop: String(item.day),
          label: String(item.day),
          header: String(item.week),
          slotName: String(item.day),
          minWidth: '100',
        })
      });
    })

    const selectionChange = (value) => {
    }

    const currentEditScheduleInfo = reactive({
      userId: '',
      day: '',
      date: dayjs(new Date()).format('YYYY-MM'),
      name: ''
    })

    const currentUpdateScheduleInfo = reactive({
      scheduleList: [],
      userId: '',
      date: dayjs(new Date()).format('YYYY-MM'),
      name: '',
      label: ''
    })
    const newDate = ref(dayjs(new Date()).format('YYYY-MM-DD'))
    const cellDblclick = (item) => {
      if (!item[item.label]) {
        ElMessage({
          message: '暂无排班!',
          type: 'warning',
        })
        return
      }

      let optDate = dayjs(item[item.label][0].shiftDate)
      let currentDate = dayjs().format('YYYY-MM-DD')
      let diff = optDate.diff(currentDate, 'minute')
      if (diff < 0) {
        ElMessage({
          message: '不能修改过期的排班!',
          type: 'warning',
        })
        return
      }

      editScheduleDialogVisible.value = true
      currentUpdateScheduleInfo.scheduleList = item[item.label]
      currentUpdateScheduleInfo.userId = item.userId
      currentUpdateScheduleInfo.name = item.userRealname
      currentUpdateScheduleInfo.label = item.label
    }
    const tableCellClick = (value) => {
      let day = value.label < 10 ? String(0 + value.label) : value.label
      if(newDate.value>currentEditScheduleInfo.date+'-'+day) return;
      currentEditScheduleInfo.userId = value.userId
      currentEditScheduleInfo.day = day
      addScheduleDialogVisible.value = true
      currentEditScheduleInfo.name = value.userRealname
      currentEditScheduleInfo.shopId = queryInfo.shopid
    }

    const handleSaveClickScheduleClick = (item) => {
      let type = item.active
      // console.log(item);

      if (type == 'first') {
        let obj = {
          shiftId: item.shiftId,
          userId: currentEditScheduleInfo.userId,
          shiftDate: currentEditScheduleInfo.date + '-' + currentEditScheduleInfo.day,
          companyId: COMPANY_ID,
          startTime: item.shiftStartTime,
          endTime: item.shiftEndTime,
          shopId: queryInfo.shopid,
          shiftName: item.shiftName
        }
        // console.log(obj);
        store.dispatch('staff/setStaffScheduleAction', obj)
      } else {
        let nameArr=[]
        item.weekInfo.shiftList.forEach(ele=>{
          nameArr.push(ele.shiftName)
        })

        let obj = {
          companyId: COMPANY_ID,
          userId: currentEditScheduleInfo.userId,
          date: currentEditScheduleInfo.date + '-' + currentEditScheduleInfo.day,
          startDay: currentEditScheduleInfo.day,
          weekInfo: item.weekInfo,
          shopId: queryInfo.shopid,
          perName:nameArr.join()
        }
        // console.log( obj);
        store.dispatch('staff/setStaffWeekScheduleAction', obj)
      }
    }

    emitter.on('addStaffScheduleDateChange', date => {
      currentEditScheduleInfo.date = date
      currentUpdateScheduleInfo.date = date
      queryInfo.date = date
    })

    const changeAddScheduleDialogVisible = (flag) => {
      addScheduleDialogVisible.value = flag
    }

    const queryInfo = reactive(
      {
        companyId: COMPANY_ID,
        date: dayjs(new Date()).format('YYYY-MM'),
        shopid: "",
        keyword: '',
        joblv: '',
        subjoblv: '',
        status: '1',
        page: 1,
        pagesize: 10
      }
    )

    const closeEditScheduleDialog = () => {
      editScheduleDialogVisible.value = false
    }

    emitter.on('addStaffScheduleShopIdChange', value => {
      queryInfo.shopid = value
    })

    watch(queryInfo, () => {
      emitter.emit('refreshStaffSchedulePageContent')
    }, {
      deep: true
    })

    const getPaibanList = () => {
      store.dispatch('staff/getscheduleStaffListAction', { ...queryInfo })
    }

    const shopList = computed(() => store.state.reserve.shopList)
    const initPage = () => {
      let newShopId = cache.getCache('newShopId')
      queryInfo.shopid = newShopId?.id||shopList.value['0'].shopId
      getPaibanList()
    }
    initPage()


    const scheduleStaffList = computed(() => store.state.staff.scheduleStaffList)
    const schedulestaffCount = computed(() => store.state.staff.schedulestaffCount)

    emitter.on('refreshStaffSchedulePageContent', () => {
      loading.value = true
      store.dispatch('staff/getscheduleStaffListAction', { ...queryInfo })
      setTimeout(() => {
        loading.value = false
      }, 1000)
    })

    const handleSetReserveState = async (item) => {
      const res = await showInShop({empId:item.userId,shopId:queryInfo.shopid})
      if (res.code == 0) {
        ElMessage({
          showClose: true,
          message: '操作成功',
          type: 'success',
        })
        getPaibanList()
      }
    }

    const active = ref('1')
    const inactive = ref('0')

    onUnmounted(() => {
      emitter.off('refreshStaffSchedulePageContent')
      emitter.off('addStaffScheduleShopIdChange')
      emitter.off('addStaffScheduleDateChange')
      emitter.off('staffSchedulingMonthChange')
    })


    return {
      active,
      inactive,
      handleSetReserveState,
      closeEditScheduleDialog,
      scheduleStaffList,
      schedulestaffCount,
      queryInfo,
      currentUpdateScheduleInfo,
      editScheduleDialogVisible,
      changeEditScheduleDialogVisible,
      handleDetailClick,
      contentTableConfig,
      isShowEditStaffDraw,
      changedEditStaffDrawer,
      pageContentTableConfig,
      selectionChange,
      tableCellClick,
      addScheduleDialogVisible,
      changeAddScheduleDialogVisible,
      COMPANY_ID,
      currentEditScheduleInfo,
      handleSaveClickScheduleClick,
      cellDblclick,
      newDate,
      loading
    }
  }
})
</script>

<style scoped lang="less">
.staff-list {
  flex: 1;
  overflow: hidden;

  .emp-name {
    .handle-wrap {
      position: relative;
    }

    .userRealname {
      margin-bottom: 10px;
    }
  }

  &:deep(.el-table) {
    .el-table__header-wrapper {
      .el-table__cell {
        border-right: 1px solid rgb(223, 223, 223);
      }
    }
  }

  .schedule {
    .item {
      // margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .edit-schedule {
    color: rgba(42, 130, 228, 1);
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
