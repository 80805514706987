<template>
  <div class="add-staff-dialog">
    <page-dialog
      :width='36'
      @changeDialogVisible='changeDialogVisible'
      :dialogVisible='dialogVisible'
      title='添加员工'
    >
      <div class="content">
        <div class="left-item">
          <div class="form">
            <el-select
              class="el-select-1"
              v-model="value"
              placeholder="按部门查看"
              size="large"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <el-select
              v-model="value"
              class="m-2"
              placeholder="按角色查看"
              size="large"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search">
            <el-input placeholder="搜索姓名手机号"></el-input>
            <el-button
              class="search-btn"
              type="primary"
            >搜索</el-button>
          </div>
          <div class="staff-list">
            <div class="tips">共30员工</div>
            <el-scrollbar height="200px">
              <template
                :key='item'
                v-for="item in 100"
              >
                <div class="staff-item">
                  <el-icon>
                    <eleme-filled />
                  </el-icon>
                  <span class="name">张星星{{item}}</span>
                </div>
              </template>
            </el-scrollbar>
          </div>
        </div>
        <div class="right-item">
          <div class="tips">
            已选员工
          </div>
          <div class="opt-staff-list">

          </div>
        </div>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
export default defineComponent({
  props: {
    dialogVisible:{
      type:Boolean,
      default:false
    }
  },
  components: {
    PageDialog
  },
  emits:['changeDialogVisible'],
  setup(props,{emit}) {
    const ruleForm=reactive({
      name:''
    })

    const changeDialogVisible=(flag)=>{
      emit('changeDialogVisible',flag)
    }
    return {
      changeDialogVisible,
      ruleForm
    }

  }
})
</script>

<style scoped lang="less">
.add-staff-dialog {
  &:deep(.el-select) {
    width: 100%;
  }
  .content {
    display: flex;
    border-top: 1px solid var(--borderColor);
    padding-top: 20px;
    .left-item {
      flex: 1;
      .form {
        display: flex;
        justify-content: space-between;
        .el-select-1 {
          margin-right: 10px;
        }
      }
      .search {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .search-btn {
          margin-left: 10px;
        }
      }
      .staff-list {
        margin-top: 20px;
        .staff-item {
          display: flex;
          align-items: center;
          margin-top: 10px;
          i {
            font-size: 32px;
          }
          .name {
            color: rgba(80, 80, 80, 1);
            font-size: 14px;
            display: flex;
            margin-left: 8px;
          }
        }
      }
    }
    .right-item {
      margin-left: 10px;
      padding-left: 10px;
      flex: 1;
      border-left: 1px solid var(--borderColor);
    }
  }
}
</style>




