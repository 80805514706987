<template>
  <div class="add-schedule-dialog">

    <page-dialog v-if="dialogVisible" :isShowFooter="true" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible" @handleSaveClick="handleSaveClick"
      :title="'修改' + ruleForm.name + ruleForm.date + '-' + ruleForm.label + '的班次'">
      <div class="schedule-list">
        <template :key="item" v-for="item in ruleForm.scheduleList">
          <div class="schedule-item">
            <div class="name">
              <el-input v-model="item.shiftName" placeholder="班次名称" />
            </div>
            <div class="time">
              <el-time-select v-model="item.startTime" :max-time="item.endTime" class="startTime" placeholder="开始时间"
                start="08:30" step="00:15" end="18:30"></el-time-select>
              <el-time-select v-model="item.endTime" :min-time="item.startTime" placeholder="结束时间" start="08:30"
                step="00:15" end="18:30"></el-time-select>
            </div>
            <div class="status">
              <div @click="handleSetStateClick(item, 1)" v-if="item.status == 2">启用</div>
              <div @click="handleSetStateClick(item, 2)" v-if="item.status == 1">停用</div>
              <div @click="handleSetStateClick(item, 9)">删除</div>
            </div>
          </div>
        </template>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import {
  defineComponent,
  reactive,
  ref,
  watch
} from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { deepClone } from '@/utils/clone'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    scheduleInfo: {
      type: Object,
      default() {
        return {}
      }
    }

  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'closeEditScheduleDialog'],
  setup(props, {
    emit
  }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }
    const ruleForm = reactive({
      scheduleList: [],
      userId: '',
      date: '',
      name: '',
      label: ''
    })

    watch(() => props.scheduleInfo, (newv) => {
      let n = deepClone(newv)
      if (n != null) {
        ruleForm.scheduleList = n.scheduleList
        ruleForm.userId = n.userId,
          ruleForm.date = n.date
        ruleForm.name = n.name
        ruleForm.label = n.label
      }

    }, {
      deep: true
    })

    const handleSaveClick = () => {
      store.dispatch('staff/piliangupDataStaffScheduleInfoAction', {
        scheduleList: ruleForm.scheduleList,
        userId: ruleForm.userId
      })
    }

    const handleSetStateClick = (item, type) => {
      let obj = {
        shiftId: item.shiftId,
        shiftDate: item.shiftDate,
        userId: ruleForm.userId,
        companyId: COMPANY_ID,
        status: type,
        startTime: item.startTime,
        endTime: item.endTime
      }
      store.dispatch('staff/updateStaffScheduleInfoAction', obj)
      emit('closeEditScheduleDialog')
    }

    return {
      handleSetStateClick,
      ruleForm,
      changeDialogVisible,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
.add-schedule-dialog {

  .schedule-list {
    .schedule-item {
      display: flex;
      margin-bottom: 10px;

      .name {
        margin-right: 10px;
      }

      .time {
        display: flex;
        margin-right: 10px;

        .startTime {
          margin-right: 10px;
        }
      }

      .status {
        min-width: 80px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        div {
          margin: 4px;
          color: rgb(64, 158, 255);
        }
      }
    }
  }
}
</style>
