<template>
  <div class="staff-list-breadcrumb">
    <breadcrumb :breadcrumbList='breadcrumbList'></breadcrumb>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
</style>




