<template>
  <el-drawer :size="windowWidth > 1360 ? '35%' : '30%'" v-if="drawer" :with-header="false" destroy-on-close
    class="drawer" v-model="drawer" :title="title">
    <el-scrollbar :height='windowsHeight + "px"'>
      <slot></slot>
    </el-scrollbar>
  </el-drawer>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, onMounted } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 80
    }
  },
  components: {

  },
  emits: ['changedDrawer'],
  setup(props, { emit }) {
    const drawer = ref(props.isShow)
    const windowsHeight = ref(600)
    const windowWidth = ref(1360)

    watch(() => props.isShow, (newValue) => {
      drawer.value = newValue
    })

    watch(drawer, (newValue) => {
      emit('changedDrawer', newValue)
    })
    function getWidth() {
      let winWidth;
      if (window.innerWidth) {
        winWidth = window.innerWidth;
      } else if ((document.body) && (document.body.clientWidth)) {
        winWidth = document.body.clientWidth;
      }
      return winWidth
    }

    function getHeight() {
      let height = (window.innerHeight) ? window.innerHeight : (document.documentElement && document.documentElement.clientHeight) ? document.documentElement.clientHeight : document.body.offsetHeight;
      return height - 40
    }


    const setSize = () => {
      windowWidth.value = getWidth()
      windowsHeight.value = getHeight()
    }


    onMounted(() => {
      setSize()
      window.addEventListener('resize', () => {
        setSize()
      })

    })

    return {
      setSize,
      drawer,
      windowsHeight,
      windowWidth
    }

  }
})
</script>

<style scoped lang="less">
.drawer {

  .drawer-box {
    overflow-y: scroll;
  }
}
</style>
<style lang="less">
&:deep(.el-drawer) {
  min-width: 500px;
  overflow: auto !important;
}
</style>





