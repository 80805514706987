import emitter from '@/utils/eventbus'
import { getCalendar } from '@/utils/time'
const contentTableConfig = {
  propList: [
    {
      prop: 'userRealname',
      label: '姓名',
      minWidth: '110',
      slotName: 'userRealname',
      fixed: true
    },{
      prop: 'isReserve',
      label: '开启预约',
      minWidth: '110',
      slotName: 'isReserve',
      fixed: true
    }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}

const dateArr = getCalendar(new Date())
dateArr.forEach((item) => {
  contentTableConfig.propList.push({
    prop: String(item.day),
    label: String(item.day),
    header: String(item.week),
    slotName: String(item.day),
    minWidth: '100'
  })
})

export { contentTableConfig }
