<template>
  <div class="edit-staff-draw">
    <page-draw
      @changedDrawer='changedDrawer'
      :isShow='isShowDraw'
    >
      <div class="teacher-detail">
        <div class="top-info">
          <el-icon class="avatar">
            <eleme-filled />
          </el-icon>
          <div class="teacher-name">王亚南 </div>
          <div class="shop">
            <span>所属店铺</span>
            <span class="shop-name"> 一指阁(静安寺店)</span>
          </div>
        </div>

        <div class="base-info">
          <div class="title">
            <div>基本信息</div>
            <div class="other">
              <span @click="handleResetPasswordClick">重置密码</span>
              <span>编辑</span>
            </div>
          </div>
          <div class="base-info-list">
            <div class="info-item">
              <div class="item-name">系统账号:</div>
              <div class="value">13909090998</div>
            </div>
            <div class="info-item">
              <div class="item-name">职级:</div>
              <div class="value">13909090998</div>
            </div>
            <div class="info-item">
              <div class="item-name">职位:</div>
              <div class="value">理疗师</div>
            </div>
            <div class="info-item">
              <div class="item-name">部门:</div>
              <div class="value">13909090998</div>
            </div>
            <div class="info-item">
              <div class="item-name">入职时间:</div>
              <div class="value">13909090998</div>
            </div>
            <div class="info-item">
              <div class="item-name">个人介绍:</div>
              <div class="value">13909090998</div>
            </div>
          </div>
        </div>

        <div class="serve">
          <div class="title">
            <div>关联服务</div>
            <div class="other">
              关联
            </div>
          </div>
          <div class="serve-list">
            <div class="serve-item">
              <div>腰部草本敷热</div>
              <div class="other">移除</div>
            </div>
            <div class="serve-item">
              <div>传统肩颈放松</div>
              <div class="other">移除</div>
            </div>
            <div class="serve-item">
              <div>无痛刮痧</div>
              <div class="other">移除</div>
            </div>
            <div class="serve-item">
              <div>全身舒缓推拿</div>
              <div class="other">移除</div>
            </div>
          </div>
        </div>
      </div>
      <page-dialog
        class="reset-password-dialog"
        title='重置密码'
        :dialogVisible='dialogVisible'
        @changeDialogVisible='changeDialogVisible'
      >
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          label-width="120px"
          class="reset-password-dialog-ruleForm"
          :size="formSize"
        >
          <el-form-item
            label="新密码"
            prop="newPassword"
          >
            <el-input v-model="ruleForm.newPassword"></el-input>
          </el-form-item>
          <el-form-item
            label="确认新密码"
            prop="surePassword"
          >
            <el-input v-model="ruleForm.surePassword"></el-input>
            <div class="tips">
              备注：新密码将通过短信的方式发送到员工
            </div>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button type="primary">提交</el-button>
        </template>
      </page-dialog>
    </page-draw>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'

export default defineComponent({
  props: {
    isShowDraw: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageDraw,
    PageDialog
  },
  emits: ['changedDrawer'],
  setup(props, { emit }) {
    const dialogVisible = ref(false)
    const changedDrawer = (flag) => {
      emit('changedDrawer', flag)
    }

    const changeDialogVisible = (flag) => {
      dialogVisible.value = flag
    }

    const handleResetPasswordClick = () => {
      dialogVisible.value = true
    }

     const ruleForm=reactive({
      newPassword:"",
      surePassword:''
    })

    return {
      changedDrawer,
      dialogVisible,
      changeDialogVisible,
      handleResetPasswordClick,
      ruleForm
    }
  }
})
</script>

<style scoped lang="less">
.edit-staff-draw {
  .teacher-detail {
    .top-info {
      height: 186px;
      background-color: rgba(231, 189, 137, 1);
      font-size: 14px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .teacher-name {
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        margin-top: 6px;
        margin-bottom: 20px;
      }
      .avatar {
        font-size: 50px;
      }
      .shop {
        width: 192px;
        height: 27px;
        line-height: 27px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        background-color: rgba(153, 101, 34, 1);
        border-radius: 16px;
        font-size: 14px;
        .shop-name {
        }
      }
    }

    .base-info {
      margin-top: 22px;
      .title {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding-left: 16px;
        color: rgba(80, 80, 80, 1);
        font-size: 14px;
        .other {
          color: rgba(42, 130, 228, 1);
          span {
            margin: 0 6px;
            cursor: pointer;
          }
        }
        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 20px;
          background-color: rgba(42, 130, 228, 1);
          left: 0;
        }
      }
      .base-info-list {
        margin-top: 30px;
        .info-item {
          display: flex;
          margin-bottom: 12px;
          .item-name {
            width: 100px;
            text-align: right;
            color: rgba(166, 166, 166, 1);
            font-size: 14px;
            margin-right: 26px;
          }
          .value {
            color: rgba(80, 80, 80, 1);
            font-size: 14px;
          }
        }
      }
    }

    .serve {
      margin-top: 22px;
      .title {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-left: 16px;
        color: rgba(80, 80, 80, 1);
        font-size: 14px;
        .other {
          color: rgba(42, 130, 228, 1);
          span {
            margin: 0 4px;
            cursor: pointer;
          }
        }

        &::before {
          content: '';
          position: absolute;
          width: 8px;
          height: 20px;
          background-color: rgba(42, 130, 228, 1);
          left: 0;
        }
      }
      .serve-list {
        padding-left: 20px;
        .serve-item {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
          color: rgba(80, 80, 80, 1);
          font-size: 14px;
          .other {
            color: rgba(42, 130, 228, 1);
          }
        }
      }
    }
  }
}
</style>




