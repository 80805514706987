<template>
  <div class="add-rank-dialog">
    <page-dialog
      @changeDialogVisible='changeDialogVisible'
      :dialogVisible='dialogVisible'
      title='新增部门'
    >
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="部门名称"
          prop="name"
        >
          <el-input v-model="ruleForm.office"></el-input>
        </el-form-item>

      </el-form>
    </page-dialog>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
export default defineComponent({
  props: {
    dialogVisible:{
      type:Boolean,
      default:false
    }
  },
  components: {
    PageDialog
  },
  emits:['changeDialogVisible'],
  setup(props,{emit}) {
    const ruleForm=reactive({
      name:''
    })
    const changeDialogVisible=(flag)=>{
      emit('changeDialogVisible',flag)
    }
    return {
      changeDialogVisible,
      ruleForm
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  &:deep(.el-select) {
    width: 100%;
  }
}
</style>




