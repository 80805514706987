<template>
  <div class="week-setting">
    <top-content @shopChange="handleShopChange" :isWeekAdd="true"></top-content>
    <page-content :dataList="scheduleWeekList" :contentTableConfig="contentWeekTableConfig">
      <template #useQty="scope">
        {{ scope.row.useQty }}
      </template>
      <template #handler="scope">
        <div class="handle-btns">
          <el-button @click="handleEditWeekClick(scope.row)" plain size="mini" v-has="'staff:cycle:edit'">编辑</el-button>
          <el-button @click="handleUpdateWeekStatusClick(scope.row, 2)" plain size="mini" v-has="'staff:cycle:delete'">
            删除</el-button>
          <!-- <el-button @click="handleDistributeClick(scope.row)" plain size="mini">适配门店</el-button> -->
        </div>
      </template>

      <template #shiftList="scope">
        <div class="shiftList">
          <template :key="item" v-for="(item, index) in scope.row.shiftList">
            <div class="shiftList-item">第{{ index + 1 }}天： {{ item.shiftName }}{{ item.startTime }} - {{ item.endTime }}
            </div>
          </template>
        </div>
      </template>

      <template #user_shop>0</template>
    </page-content>
    <distribute-dialog :weekId="currentEditWeekId" :dialogVisible="distributeDialogVisible"
      @changeDialogVisible="changeDistributeDialogVisible"></distribute-dialog>
    <edit-week-schedule-dialog :weekInfo="currentWeekInfo" @changeDialogVisible="changeEditWeekScheduleDialogVisible"
      :dialogVisible="editWeekScheduleDialogVisible"></edit-week-schedule-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue';
import topContent from '../top-content/top-content.vue';
import { contentWeekTableConfig } from '../../../config'
import { useStore } from 'vuex';
import { COMPANY_ID } from '@/common/constant';
import emitter from '@/utils/eventbus';
import distributeDialog from './cpns/distribute-dialog/distribute-dialog.vue';
import editWeekScheduleDialog from './cpns/edit-week-schedule-dialog/edit-week-schedule-dialog.vue';
import { removePeriod } from '@/service/main/staff'
export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    topContent,
    distributeDialog,
    editWeekScheduleDialog
  },
  setup() {

    const store = useStore()
    const scheduleWeekList = computed(() => store.state.staff.scheduleWeekList)

    const editWeekScheduleDialogVisible = ref(false)
    const changeEditWeekScheduleDialogVisible = (flag) => {
      editWeekScheduleDialogVisible.value = flag
    }

    const currentWeekInfo = ref('')


    const handleEditWeekClick = (item) => {
      currentWeekInfo.value = item
      editWeekScheduleDialogVisible.value = true
    }

    const ruleForm = reactive({
      companyId: COMPANY_ID,
      shopId: "0"
    })

    const currentEditWeekId = ref('')
    const initPage = () => {
      store.dispatch('staff/getScheduleWeekList', [COMPANY_ID, ruleForm.shopId])
    }
    initPage()

    emitter.on('refreshScheduleWeekListPage', () => {
      initPage()
    })

    const handleUpdateWeekStatusClick = async (item, type) => {
      store.dispatch('staff/updateWeekStatusAction', [item.id, COMPANY_ID])
    }

    const distributeDialogVisible = ref(false)
    const changeDistributeDialogVisible = (flag) => {
      distributeDialogVisible.value = flag
    }

    const handleDistributeClick = (item) => {
      currentEditWeekId.value = item.id
      distributeDialogVisible.value = true
    }

    const handleShopChange = (value) => {
      ruleForm.shopId = value
      initPage()
    }


    return {
      ruleForm,
      handleShopChange,
      changeDistributeDialogVisible,
      distributeDialogVisible,
      contentWeekTableConfig,
      scheduleWeekList,
      currentEditWeekId,
      handleDistributeClick,
      changeEditWeekScheduleDialogVisible,
      editWeekScheduleDialogVisible,
      handleEditWeekClick,
      currentWeekInfo,
      handleUpdateWeekStatusClick

    }

  }
})
</script>

<style scoped lang="less">
.week-setting {
  margin-top: 20px;
}
</style>




